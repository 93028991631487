.submit {
  background-color: black;
  border-radius: 5px;
  padding: 20px;
}

body {
  margin: 0px;
}

a {
  color: #0078ff;
}
